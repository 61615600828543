import { createStore } from 'vuex'

export default createStore({
  state: {
    password: "design works",
    inputPassword: null,
    months:[
      {id:1, text:"January"},
      {id:2, text:"February"},
      {id:3, text:"March"},
      {id:4, text:"April"},
      {id:5, text:"May"},
      {id:6, text:"June"},
      {id:7, text:"July"},
      {id:8, text:"August"},
      {id:9, text:"September"},
      {id:10, text:"October"},
      {id:11, text:"November"},
      {id:12, text:"December"},
    ],
    managerPageName: "home"
  },
  getters:{
    months: state => state.months,
    getMonths: (state) => (id) =>{
      let name = ""
      state.months.forEach((object)=>{
        if(object.id === id){
          name = object.text
        }
      })
      return name
    },
    getManagerPageName: state => state.managerPageName
  },
  mutations: {
    inputPassword(state, payload){
      state.inputPassword = payload
    },
    managerPageName(state, payload){
      state.managerPageName = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
