export default class Element{

  id = null 
  title = ""
  firstText = ""
  secondText = ""
  movie = null
  month = null
  day = null
  year = null
  imageObjects = []
  isPublic = true
  createdAt = null
  updatedAt = null
  updateCounter = 0

  constructor(element){
    if(element == null){return}
    this.id = element.id
    this.title = element.title
    this.firstText = element.firstText
    this.secondText = element.secondText
    this.movie = element.movie
    this.month = element.month
    this.day = element.day
    this.year = element.year
    this.imageObjects = element.imageObjects
    this.isPublic = element.isPublic
    this.createdAt = element.createdAt
    this.updatedAt = element.updatedAt
    this.updateCounter = element.updateCounter
  }

}