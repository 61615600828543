<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div v-if="element.isPublic" class="public">

        <div class="title">
          <h2>{{element.title}}</h2><p class="">{{element.subTitle}}</p>
        </div>
        <img :src="topImageUrl" :alt="element.title" class="top-image">
        <div  class="content" >
          <div class="container">
            <div class="_left">
              <div class="_left-content">
              </div>
            </div>
            <div class="_right">
              <div class="_right-content">
                <p class=" _text _marginS">
                  {{element.firstText}}
                </p>
  
                <div v-if="element.movie !== null" class="movie _marginS">
                  <!-- <div class="movie-cover-top"></div> -->
                  <iframe :src="`https://www.youtube.com/embed/${element.movie}?controls=0&modestbranding=1&showinfo=0&autohide=1&playsinline=1`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" muted autoplay allowfullscreen></iframe>
                  <!-- <div class="movie-cover-bottom"></div> -->
                </div>
                <img v-else :src="subImageUrl" alt="" class="sub-image">
                <p class="_text _marginS">
                  {{element.secondText}}
                </p>
                <div v-if="image1Url = null" class="images">
                </div>
                <div v-else-if="image2Url = null" class="images">
                  <img :src="image1Url" alt="" class="image-one">
                </div>
                <div v-else-if="image3Url = null" class="images">
                  <img :src="image1Url" alt="" class="image-one">
                  <img :src="image2Url" alt="" class="image-one">
                </div>
                <div v-else-if="image4Url = null" class="images">
                  <img :src="image1Url" alt="" class="image-one">
                  <div class="images-box">
                    <img :src="image2Url" alt="" class="image-two">
                    <img :src="image3Url" alt="" class="image-two">
                  </div>
                </div>
                <div v-else class="images">
                  <img :src="image1Url" alt="" class="image-one">
                  <img :src="image2Url" alt="" class="image-one">
                  <div class="images-box">
                    <img :src="image3Url" alt="" class="image-two">
                    <img :src="image4Url" alt="" class="image-two">
                  </div>
                  
                </div>
                <p class="journal-day _eg">{{element.month}} {{element.day}} {{element.year}}</p>
                <div class="move">
                  <div class="move-box" >
                    <img src="@/assets/images/arrow-l.png" alt="">
                    <p class="move-text _eg">Back</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer :pageTitle="pageTitle"></Footer>

    </div>
    <div v-else class="unpublic">
      <div class="title">
        <h2>この記事は非公開です。</h2>
      </div>
      <Footer :pageTitle="pageTitle"></Footer>
    </div>
    
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'
import Element from '@/class/element.js'
import {db} from '@/firebase/index.js'



export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Experiment",
      isOpenNavi: false,

      element: new Element(),

      topImageUrl: null,
      subImageUrl: null,
      image1Url: null,
      image2Url: null,
      image3Url: null,
      image4Url: null,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    db.collection('element').doc(this.$route.params.id).get()
    .then((doc)=>{
      this.element = doc.data()

      if(this.element.movie === null || this.element.movie === ""){
        this.element.movie = null
        
        this.element.imageObjects.forEach((image) => {
          
          if(image.order === 0){
            this.topImageUrl = image.url
          }else if(image.order === 1){
            this.subImageUrl = image.url
            console.log(this.subImageUrl)
          }else if(image.order === 2){
            this.image1Url = image.url
          }else if(image.order === 3){
            this.image2Url = image.url
          }else if(image.order === 4){
            this.image3Url = image.url
          }else if(image.order === 5){
            this.image4Url = image.url
          }
        })
      }else{
        // 動画がある時
        
        this.element.imageObjects.forEach((image) => {
          if(image.order === 0){
            this.topImageUrl = image.url
          }else if(image.order === 1){
            this.image1Url = image.url
          }else if(image.order === 2){
            this.image2Url = image.url
          }else if(image.order === 3){
            this.image3Url = image.url
          }else if(image.order === 4){
            this.image4Url = image.url
          }
        })
      }
    })
    .catch((error)=>{
      console.log(error.messege)
    })
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },



  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  margin-left: 42%;
  display: flex;
  padding: 180px 0 40px;
}
.title h2{
  font-size: 2.0rem;
  letter-spacing: 1px;
}
.title p{
  font-size: 1.4rem;
  color: var(--gray);
  -webkit-transform: translate(15px, 7px);
  transform: translate(15px, 7px);
}
.top-image{
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.content{
  width: 100%;
  padding: 60px 0 100px;
}
.container{
  width: 100%;
  padding-bottom: 160px;
  display: flex;
}

.movie {
  width: 100%;
  aspect-ratio: 16 / 9;
  /* aspect-ratio: 1 / 1; */
  position: relative;
}
.movie iframe {
  width: 100%;
  height: 100%;
}
.movie-cover-top{
  position: absolute;
  width: 100%;
  height: 22%;
  top: 0;
  left: 0;
  background-color: var(--bg);
}
.movie-cover-bottom{
  position: absolute;
  width: 100%;
  height: 22%;
  bottom: 0;
  left: 0;
  background-color: var(--bg);
}
.sub-image{
  width: 100%;
  object-fit: cover;
  aspect-ratio: 5 / 3;
}

.images{
  margin-bottom: 60px;
}
.image-one{
  width: 100%;
  aspect-ratio: 5 / 3;
  object-fit: cover;
  margin-bottom: 10px;
  display: block;
}
.images-box{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.image-two{
  width: calc(50% - 5px);
  display: block;
  aspect-ratio: 3 / 4;
  object-fit: cover;
}
.journal-day{
  color: var(--gray);
  font-size: 1.5rem;
  margin: 40px 0 50px ;
}
.move{
  display: flex;
  justify-content: space-between;
  width: 170px;
}
.move-box{
  display: flex;
  justify-content: space-between;
  width: 50px;
  transition: 0.3s;
}
.move-box:hover{
  filter: brightness(10%);
}
.move-box img{
  width: 15px;
  height: 15px;
}
.move-text{
  color: var(--gray);
  font-size: 1.4rem;
  line-height: 15px;
}
.unpublic{
  padding-top: 100px;
}


.appear{
  opacity: 1;
}
@media screen and (max-width:599px){

  .title{
    width: 90%;
    margin: 0 auto;
    padding: 120px 0 40px;
    flex-direction: column;
  }
  .title p{
    -webkit-transform: translate(0px, -6px);
    transform: translate(0px, -6px);
  }
  .container{
    width: 90%;
    margin: 0 auto;
    display: block;
    padding-bottom: 100px;
    
  }
  .top-image{
    height: 250px;
  }
  .unpublic{
  padding-top: 0px;
  }




}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
/* タブレット */
@media screen and (min-width:1200px) {
  .image{
    height: 700px;
  }
}
</style>