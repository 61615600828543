<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    
    <div  class="_content" >
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Concept</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <!-- <p class="_text _marginLL"> 英文きたらこのmargin付きのやつを使う -->
            <p class="_text ">
              自然と向き合い、そこから享受したものを必然の形に落とし込むこと。<br>
              JIBIは、ものづくりを通じて未来へ連綿と続いてゆく文化創造の一旦を担い、美しさの本質を次代へと繋ぐ活動を目指しています。
            </p>
            <p class="_text _eg concept-eg-text">
              We look to nature for inspiration, which inevitably shapes our work.<br>
              With creativity as the vehicle, JIBI aims to be part of creating a culture<br>
              and passing the essential qualities of our time onto the next generation.
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Profile</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <img  src="@/assets/images/profile.jpg" alt="Matsuta Shogō" class="profile-image">
            <div class="profile">
              <p class="profile-name">松田 翔剛<span class="_eg-b">Matsuta Shogo</span></p>
              <p class="profile-position _eg">JIBI Co.,Ltd Founder / Designer</p>
            </div>
            <p class="profile-text _marginLL">
              埼玉県生まれ。<br>
              2008年、株式会社SIMPLICITY入社。建築・内装設計部門チーフデザイナー兼マネージャーとして約10年間所属した後、2017年独立。島国日本の縮図である「離島」という環境に身を置くため各地を廻り、拠点を東京から山口県大津島へと移す。2018年、大津島の文化を受け継ぎ次世代へと繋ぐため、一般社団法人 磊ノ島設立、理事に就任。2019年、自身のデザイン表現の構築、発信の場として株式会社JIBIを設立する。地域と共に造りあげるプロジェクトから海外案件まで、幅広く活動を行う。
            </p>
            <p class="_text profile-box">
              覚えている限りの、古い記憶。<br>
              記憶を一つずつ遡ってゆき、甦る幼い記憶達。<br><br>

              大きなタイヤや流木が流れつく、広い砂浜。<br>
              もたもたと一歩ずつ進み、波が打ち寄せる少し手前で座り込んで見た、眼前に広がる曇り空とグレーの海。<br>
              その時驚くほどに切ない気持ちが襲ってきた。<br>
              自分の手の届く範囲のなかで、人工物に囲まれて生きていた私に取って、想像すらしていなかった広く大きな世界。<br><br>

              はじめて私は「自分」という小さな個の存在を自覚した。<br><br>

              近所の公園で砂遊びをしながら、恐らくはじめて作ってみたであろう、泥団子。<br>
              夢中で握り、地面に並べては母に得意気な眼差しを向け「上手」と言わせた。それから物心つくまで、内緒で続けた一人遊びの泥団子。<br>
              ボコボコの歪なかたちから、まん丸で真っ黒な団子作りへと変貌してゆく。<br><br>

              私にとって、土は好奇心の象徴となった。<br><br>

              遠足で行った森の中で肌に触れる、ひんやりとした空気。<br>
              公園を裸足で走った時の、刺さるような芝生の感触。<br>
              木登りをした時に掌で掴む、ざらざらとごつごつ。<br>
              あの時の、あの瞬間の感触が結びつける、記憶の風景。<br><br>

              気が付けば、今はそうした記憶達と再び出会い、離島での暮らしに興じている。<br><br>

              自然との結びつきそのものが、ものづくりへ向かおうとする衝動の原点なのだ。<br><br>

              自然との距離が近い場所で、豊かさの本質を見つめ直し、未来の子供達やこの星に、大切な何かを残せる活動を一つひとつ紡いでゆきたい。<br><br>

              ヒトも自然。<br>
              自然を引き寄せ、寄り添いながら、未来の誰かの記憶の糧となるために<span class="dash">――</span>
            </p>
            <p class="_eg profile-box-eg">
              I have memories from as far as I can remember, which I can recall one by one.<br><br>

              On a vast sandy beach, driftwood and tires are washed ashore.<br>
              I move step by step and sit down just before the waves break,<br class="_PC-br">
              watching the cloudy sky and grey sea right in front of me.<br class="_PC-br">
              I suddenly get hit by a monumental feeling of suffocation.<br class="_PC-br">
              I’d been living in a world where everything within reach was a man-made object,<br class="_PC-br">
              but beyond it was an immense universe that I couldn't even imagine.<br><br>

              For the first time, I became aware of my own small existence.<br><br>

              Playing at a nearby park, I made a ball of mud, probably for the first time.<br>
              I was in a complete trance as I gripped the soil, lining up the balls I’d made,<br class="_PC-br">
              proudly turning to see my mother’s face as she said “very good.”<br class="_PC-br">
              From then on, I continued to make these mud balls until I felt I had mastered it.<br class="_PC-br">
              The irregular shapes with holes that I’d been making soon became perfectly round and black.<br><br>

              From this memory, soil became a symbol of curiosity for me.<br><br>

              The cool air that graced my skin as I adventured through the forest<span class="dash">――</span><br>
              The feeling of grass almost piercing my feet as I ran barefoot through the park<span class="dash">――</span><br>
              The rough and rugged texture that met my palms as I climbed trees<span class="dash">――</span><br>
              The sensations of those moments are tied in a knot with the landscape of my memory.<br><br>

              My decision to move to a remote island must have been driven by the desire to meet those memories again.<br><br>

              My urge to create things began with this very connection to nature.<br><br>

              As I reconsider the essence of abundance in a place so close to nature,<br class="_PC-br">
              I want to create things that leave something important for the children of the future and for this planet.<br><br>

              Humans are also a part of nature.<br>
              As I pull myself towards nature and get closer to it,<br class="_PC-br">
              my purpose becomes to nourish the memories of people of the future.
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Members</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <div class="member-profile">
              <p class="profile-name">松田 翔剛<span class="_eg-b">Matsuta Shogo</span></p>
              <p class="profile-position _eg">Founder / Designer</p>
            </div>
            <div class="member-profile">
              <p class="profile-name">西村 尚倫<span class="_eg-b">Nishimura Naotoshi</span></p>
              <p class="profile-position _eg">Managing Director</p>
            </div>
            <div class="member-profile">
              <p class="profile-name">伊藤 有美<span class="_eg-b">Ito Yumi</span></p>
              <p class="profile-position _eg">Brand Manager / Designer</p>
            </div>
            <div class="member-profile">
              <p class="profile-name">TAMOTSU</p>
              <p class="profile-position _eg">Web Engineer / Photographer</p>
            </div>
            <div class="member-profile">
              <p class="profile-name">花塚 明菜<span class="_eg-b">Hanazuka Akina</span></p>
              <p class="profile-position _eg">Project Manager</p>
            </div>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Company Profile</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="info">
              株式会社 <span class="_eg jibi">JIBI</span><br>
              <span class="_eg">JIBI Co.,Ltd</span>
            </p>
            <p class="info">
              〒745-0057 山口県周南市大津島 1456<br>
              <span class="_eg">1456 Ohzu-island, Shunan, Yamaguchi<br>745-0057, Japan</span>
            </p>
            <a class="info mail" href="mailto:info@jibi.co.jp">info@jibi.co.jp</a>
            <p class="info">
              Branding design / Interior design / Furniture design<br>
              Graphic design / Web design / Art work creation
            </p>
            <p class="info">Our brands | 陽森 / mui</p>
          </div>
        </div>
      </div>
      
      
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "About",
      isOpenNavi: false,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.profile-image{
  width: 100%;
  max-width: 400px;
  margin-bottom: 40px;
}
.profile-name{
  font-size: 2rem;
  font-weight: 700; 
}
.profile-name span{
  margin-left: 20px;
}
.profile-position{
  color: var(--gray);
  font-size: 1.4rem;
  margin-bottom: 30px;
}
.profile-text{
  line-height: 28px;
  letter-spacing: 1px;
  text-align: justify;
  margin-bottom: 80px;
}
.profile-box{
  max-width: 578px;
}
.profile-box-eg{
  line-height: 28px;
  letter-spacing: 1px;
  margin-top: 80px;
}
.member-profile{
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
  margin-bottom: 40px;
}
.info{
  font-size: 1.8rem;
  line-height: 36px;
  margin-bottom: 40px;
}
.mail{
  display: block;
  text-decoration:underline;
  text-decoration-color:var(--black);
}
.jibi{
  font-size: 2rem;
}
.concept-eg-text{
  margin-top: 40px;
}
.dash {
	letter-spacing: -4px;
	margin: 0 4px 0 2px;
	font-family: Meiryo;
}
@media screen and (max-width:599px){
  .profile-image{
    max-width: 600px;
  }
  .profile-name{
    font-size: 1.8rem;
    font-weight: 700; 
  }
  .member-profile{
    margin-bottom: 10px;
  }
  .info{
    font-size: 1.5rem;
    margin-bottom: 14px;
  }

}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>