<template>
  <div class="m_base">
    <p class="m_title">New Experiment</p>
    <div class="m_tag">
      <div class="m_tag-box">
        <input type="radio" id="public" :value="true" v-model="element.isPublic">
        <label for="public" class="m_tag-label"><span v-if="element.isPublic" class="m_tag-active"></span></label>
        <label for="public" class="m_tag-text _eg">Public</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="unpublic" :value="false" v-model="element.isPublic">
        <label for="unpublic" class="m_tag-label"><span v-if="!element.isPublic" class="m_tag-active"></span></label>
        <label for="unpublic" class="m_tag-text _eg">Unpublic</label>
      </div>
    </div>
    <div class="m_input-box m_box">
      <label for="title" class="m_input-label _f4 _eg">Title</label>
      <input id="title" v-model="element.title" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="text" class="m_input-label _f4 _eg">First Text</label>
      <textarea id="text" v-model="element.firstText" placeholder="" class="m_textarea"></textarea>
    </div>
    <div class="m_input-box m_box">
      <label for="en-text" class="m_input-label _f4 _eg">Second Text</label>
      <textarea id="en-text" v-model="element.secondText" placeholder="" class="m_textarea"></textarea>
    </div>
    <div class="m_input-box m_box">
      <label for="movie" class="m_input-label _f4 _eg">Movie's ID</label>
      <input id="movie" v-model="element.movie" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="image-upload">
      <label for="image" class="m_input-label _f4 _eg">Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in imageDisp"
              :key="index"
              draggable="true"
              @dragstart="dragstart(imageObject, $event)"
              @dragenter="dragenter(imageObject)"
              @dragover.stop.prevent="dragover"
              @dragend.stop.prevent="dragend"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index)" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="preview" @change="setImage($event)" accept="image/png, image/jpeg" id="image" class="_hide">
          <label for="image" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <!-- <div class="image-upload">
      <label for="image" class="m_input-label _f4 _eg">Photos</label>
      <div >
        <draggable v-model="imageObjects" group="people" item-key="id" handle=".handle" class="image-upload-container">
          <template #item="{element}"  class="box preview">
            <div>
              <div class="preview-image" :style="{'background-image': 'url(' + element.url +')'}"></div>
              <img @click="deleteImageObject(element)" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
            </div>

          </template>
        </draggable>
        <div class="box image-upload-button">
          <input type="file" ref="preview" @change="setImage($event)" accept="image/png, image/jpeg" id="image" class="_hide">
          <label for="image" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>
    <draggable v-model="imageObjects" group="people" item-key="id" handle=".handle">
      <template #item="{element}">
        <div class="drag-item">
          <span class="handle">ここここ</span>
          {{element.order}}
        </div>

      </template>
    </draggable> -->

    <button v-if="mode === 'create'" @click="saveImageAndCreate()" :disabled="v$.element.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.element.$invalid}" class="m_submit _eg">Create</button>
    <button v-if="mode === 'edit'" @click="edit()" :disabled="v$.element.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.element.$invalid}" class="m_submit _eg">Edit</button>
    <button v-if="mode === 'edit'" @click="deleteElement()" :disabled="isButtonLock" class="m_delete _eg">Delete</button>
    <p v-if="isError" class="m_error">エラー発生。再度リトライしてください。</p>


  </div>
</template>

<script>

import "@/css/manager.css"
import { db, storage, currentTime, getDate} from '@/firebase/index.js'
import Element from "@/class/element.js"
import imageCompression from 'browser-image-compression'
import  useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import draggable from "vuedraggable"

export default {
  components:{
    // draggable
  },
  props:{
    is_sign: Boolean
},
  data(){
    return{
      pageName: "element",
      isError: false,
      isButtonLock: false,
      mode: "create", //"create" or "edit"

      element: new Element(),
      compressedImage: null,
      previewImageUrl: null,
      imageChangeCounter : 0,

      imageObjects:[],
      imageTrashBox:[],
      draggingItem: null, // ドラッグ中の要素を保持するための変数

    }
  },
  setup(){
    return { v$: useVuelidate()}
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(this.$route.params.id == null){
      this.mode = "create"
      this.element = new Element()
    }else{
      this.mode = "edit"
      db.collection('element').doc(this.$route.params.id).get()
      .then((doc)=>{
        this.element = new Element(doc.data())
        this.imageObjects = this.element.imageObjects
        this.imageObjects.sort((a,b)=>{
          if(a.order < b.order){return -1}
          if(a.order > b.order){return 1}
          return 0
        })
      })
    }
    console.log(this.mode)
  },
  mounted(){

  },
  methods:{
    async setImage(event){
      // プレビュー用
      const file = this.$refs.preview.files[0]
      
      // 保存用
      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 1.0, //最大ファイルサイズ
        maxWidthOrHeight: 2000, //最大縦横値
      }
      const compressedImage = await imageCompression(originImage, options)
      this.imageObjects.push({url: URL.createObjectURL(file), file: compressedImage})
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
      this.$refs.preview.value = null
    },
    deleteImageObject(index){
      if(this.imageObjects[index].name){
        const imageTrash = this.imageObjects[index]
        this.imageTrashBox.push(imageTrash)
        this.imageObjects.splice(index, 1)
      }else{
        this.imageObjects.splice(index, 1)
      }
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
    },

    async saveImageAndCreate(){
      this.isButtonLock = true
      let elementId = null
      this.imageObjects.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      let imageObjectsForSave = []
      this.element.createdAt = currentTime
      this.element.updatedAt = currentTime
      const date = getDate()
      this.element.month = date.month
      this.element.day = date.day
      this.element.year = date.year
      db.collection('element').add({...this.element})
      .then(async (doc)=>{
        elementId = doc.id
        db.collection('element').doc(elementId).update({ id: elementId})

        const imageObjectsToReturn = []
        const promises = []
        this.imageObjects.forEach((imageObject, index)=>{
          const fileName = `element/${elementId + "_" + String(this.element.updateCounter) + "_" + String(index)}`
          promises.push(storage.ref().child(fileName).put(imageObject.file)
          .then( async (snapShot)=>{
            const url = await snapShot.ref.getDownloadURL()
            imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
          }))
        })
        imageObjectsForSave = await Promise.all(promises).then(()=>{return imageObjectsToReturn})
        
        db.collection('element').doc(elementId).update({ imageObjects: imageObjectsForSave})
        .then(()=>{
          this.isButtonLock = false
          this.$router.push({name: 'ManagerElement'})
        })
        .catch((error)=>{
          console.log(error.message)
          db.collection('element').doc(elementId).delete()
          this.isButtonLock = false
          this.isError = true
        })
      })
      .catch((error)=>{
        console.log(error.message)
        this.isButtonLock = false
        this.isError = true
      })
    },

    async edit(){
      this.isButtonLock = true
      this.imageObjects.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      let imageObjectsForStorage = this.imageObjects.filter((object)=>{
        return object.name == null
      })
      this.imageObjects = this.imageObjects.filter((object)=>{
        return object.name != null
      })
      this.element.updateCounter = this.element.updateCounter + 1

      const elementId = this.$route.params.id
      const imageObjectsToReturn = []
      const promises = []
      imageObjectsForStorage.forEach((imageObject, index)=>{
        const fileName = `element/${elementId + "_" + String(this.element.updateCounter) + "_" + String(index)}`
        promises.push(storage.ref().child(fileName).put(imageObject.file)
        .then( async (snapShot)=>{
          const url = await snapShot.ref.getDownloadURL()
          imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
        }))
      })
      let imageObjectsFromStorage = await Promise.all(promises).then(()=>{return imageObjectsToReturn})

      imageObjectsFromStorage.forEach((imageObject)=>{
        this.imageObjects.push(imageObject)
      })
      this.imageTrashBox.forEach((object)=>{
        storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        })
      })
      this.element.imageObjects = this.imageObjects
      this.element.updatedAt = currentTime
      db.collection('element').doc(this.$route.params.id).update({...this.element})
      .then(()=>{
        this.isButtonLock = false
        this.$router.push({name: 'ManagerElement'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.isError = true
      })
    },

    async deleteElement(){
      const promises = []
      this.element.imageObjects.forEach((object)=>{
        promises.push(storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        }))
      })
      await Promise.all(promises)
      .then(()=>{
        db.collection('element').doc(this.element.id).delete()
        .then(()=>{
          this.$router.push({name: 'ManagerElement'})
        })
        .catch((error)=>{
          console.log(error.message)
          this.isError = true
        })
      })
    },

    dragstart(object, e) {
      this.draggingItem = object; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = 'move'; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(object) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [object.order, this.draggingItem.order] = [this.draggingItem.order, object.order];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = 'move'; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },

    a(){
      console.log(JSON.stringify(this.imageObjects))
    },

  },

  computed:{
    imageDisp() {

      return [...this.imageObjects].sort((a, b) => a.order - b.order)
    }
  },
  watch: {
    imageDisp:{
      deep: true,
      handler: function (){
        return this.imageObjects.sort((a, b) => a.order - b.order)
      }
    }
  },
  validations(){
    return{
      element:{
        title:{
          required
        },
      },

    }
  },
}
</script>


<style scoped>
.image{
  width: 100%;
}

.image-upload{
  margin-bottom: 22px;

}
.image-upload-label{
  font-size: 1.3rem;
  color: var(--gray-hi);
}
.image-upload-container{
  margin-top: 5px;
  display: flex;
  white-space:nowrap;
  overflow-x: auto;
}
.box{
  margin-right: 10px;
  display: block;
  width: 100px;  
}
.image-upload-icon{
  display: block;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
    border: 1px solid #b3a69f;
}

.camera-icon{
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.image-upload-icon p{
  color: var(--gray-hi);
  position: absolute;
  top: 0px;
  left: 5px;
  font-weight: 700;
}
.preview{
  position: relative;
}
.preview-image{
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}
.image-delete{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  right: 4px;
}

@media screen and (min-width:600px){

}

</style>