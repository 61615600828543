<template>
  <div class="base">
    <div class="header">
      <div class="header-left">
        <router-link :to="{name: 'Home'}"><img src="@/assets/images/logo.png" alt="ロゴ" class="header-logo"></router-link>
        <router-link :to="{name: 'Manager'}" class="manager-logo _eg">Manager</router-link>
      </div>
      <div class="header-right">
        <p v-if="is_sign" @click="signOut()" class="_f4">Sign Out</p>
        <router-link v-else :to="{name: 'ManagerSignIn'}" class="_f4" >Sign In</router-link>
      </div>
    </div>
    <div class="main">
      <div class="side-bar">
        <div class="side-container">
          <router-link :to="{name: 'ManagerJournal'}" class="side-box" >
            <p class="_eg side-box-text" :class="{'current-page':$store.state.managerPageName === 'journal'}">Journal</p>
          </router-link>
          <router-link :to="{name: 'ManagerWork'}" class="side-box">
            <p class="_eg side-box-text" :class="{'current-page':$store.state.managerPageName === 'work'}">Work</p>
          </router-link>
          <router-link :to="{name: 'ManagerTopImages'}" class="side-box">
            <p class="_eg side-box-text" :class="{'current-page':$store.state.managerPageName === 'topImage'}">Top Images</p>
          </router-link>
          <router-link :to="{name: 'ManagerMember'}" class="side-box">
            <p class="_eg side-box-text" :class="{'current-page':$store.state.managerPageName === 'member'}">Member</p>
          </router-link>
          <!-- <router-link :to="{name: 'ManagerElement'}" class="side-box">
            <p class="_eg">Experiment</p>
          </router-link> -->
        </div>

      </div>
      <div class="content">
        <router-view :is_sign="is_sign"></router-view>
      </div>
    </div>

  </div>
</template>

<script>

import {auth} from '@/firebase/index.js'


export default {
  components:{

  },
  props:{

},
  data(){
    return{
      is_sign: false
    }
  },
  beforeCreate(){

  },
  async created(){
    await auth.onAuthStateChanged(user=>{
      if(user){
        console.log("login")
        this.is_sign = true
      }else{
        console.log("logout")
        this.is_sign = false
      }
    })
  },
  mounted(){

  },
  methods:{
    setManagementPageType(type){
      this.managementPageType = type
    },
    signOut(){
      auth.signOut()
      .then(()=>{
        this.$router.push({name:'Home'})
      })
    }
  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.base{

}
.header{
  width: 100%;
  min-width: 970px;
  height: 66px;
  display: flex;
  justify-content: space-between;
}
.header-left{
  position: relative;

}
.header-logo{
  position: absolute;
  top: 50%;
  left: 20px;
    width: 60px;
  height: 30px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.manager-logo{
  position: absolute;
  top: 34px;
  left: 100px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: 600;
  font-size: 1.8rem;
  color: var(--gray-low);
}
.header-right{
  line-height: 66px;
  margin-right: 20px;
}

.main{
  display: flex;
  min-width: 970px;
}
.side-bar{
  width: 200px;
  height: calc(100vh - 66px);
  background-color: var(--gray-bg);
}
.side-container{
  padding-top: 40px;
}
.side-box{
  display: flex;
  height: 60px;
  position: relative;
}
.side-box img{
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 32px;
  height: 32px;
}
.side-box-text{
  color: var(--white);
  font-size: 2rem;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  left: 20px;
  letter-spacing: 1px;
}

.current-page{
  color: #000000;
}


.content{
  width: calc(100vw - 200px);
  height: calc(100vh - 66px);
  background-color: var(--bg);
  overflow-x: scroll;
  min-width: 720px;
}


@media screen and (min-width:600px){

}

</style>